/*
 * Component: Main Header
 * ----------------------
 */

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
  //Navbar
  .navbar {
		
		
		 
    .transition(margin-left @transition-speed @transition-fn);
    margin-bottom: 0;
    margin-left: @sidebar-width;
    border: none;
    min-height: @navbar-height;
    border-radius: 0;
    .layout-top-nav & {
      margin-left: 0;
    }
  }
  //Navbar search text input
  #navbar-search-input.form-control {
    background: rgba(255, 255, 255, .2);
    border-color: transparent;
    &:focus,
    &:active {
      border-color: rgba(0, 0, 0, .1);
      background: rgba(255, 255, 255, .9);
    }
    &::-moz-placeholder {
      color: #ccc;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #ccc;
    }
    &::-webkit-input-placeholder {
      color: #ccc;
    }
  }
  //Navbar Right Menu
  .navbar-custom-menu,
  .navbar-right {
    float: right;
    @media (max-width: @screen-sm-max) {
      a {
        color: inherit;
        background: transparent;
      }
    }
  }
  .navbar-right {
    @media (max-width: @screen-header-collapse) {
      float: none;
      .navbar-collapse & {
        margin: 7.5px -15px;
      }

      > li {
        color: inherit;
        border: 0;
      }
    }
  }
  //Navbar toggle button
  .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: @navbar-padding-vertical @navbar-padding-horizontal;
    //Add the fontawesome bars icon
    font-family: fontAwesome;
    &:before {
      content: "\f0c9";
    }
    &:hover {
      color: #fff;
    }
    &:focus,
    &:active {
      background: transparent;
    }
		
		@media (min-width: @screen-xs-max) {
			display: none;
			//background: red;
		 }
  }
  .sidebar-toggle .icon-bar {
    display: none;
  }
  //Navbar User Menu
  .navbar .nav > li.user > a {
    > .fa,
    > .glyphicon,
    > .ion {
      margin-right: 5px;
    }
  }

  //Labels in navbar
  .navbar .nav > li > a > .label {
    position: absolute;
    top: 10px;
    right: 20px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: .9;
		color: @gray;
  }

  //Logo bar
  .logo {
    .transition(width @transition-speed @transition-fn);
    display: block;
    float: left;
    height: @navbar-height;
    font-size: 20px;
    line-height: 50px;
    text-align: left;
    width: @sidebar-width;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0 20px;
    font-weight: 300;
    overflow: hidden;
    //Add support to sidebar mini by allowing the user to create
    //2 logo designs. mini and lg
    .logo-lg {
      //should be visibile when sidebar isn't collapsed
      display: block;
    }
    .logo-mini {
      display: none;
    }
  }
  //Navbar Brand. Alternative logo with layout-top-nav
  .navbar-brand {
    color: #fff;
  }
}

// Content Header
.content-header {
  position: relative;
  padding: 30px 20px 0 20px;
  // Header Text
  > h1 {
    margin: 0;
    font-size: 50px;
    > small {
      font-size: 15px;
      display: inline-block;
      padding-left: 4px;
      font-weight: 300;
    }
  }

  > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 30px 20px;
    position: absolute;
    top: 15px;
    right: 10px;
    .border-radius(2px);
    > li > a {
      color: #444;
      text-decoration: none;
      display: inline-block;
      > .fa, > .glyphicon, > .ion {
        margin-right: 5px;
      }
    }
    > li + li:before {
      content: '>\00a0';
    }
  }

  @media (max-width: @screen-sm-max) {
    > .breadcrumb {
      position: relative;
      margin-top: 5px;
      top: 0;
      right: 0;
      float: none;
      background: @gray-lte;
      padding-left: 10px;
      li:before {
        color: darken(@gray-lte, 20%);
      }
    }
  }
}

.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0;
  padding: @navbar-padding-vertical @navbar-padding-horizontal;
}

//Control navbar scaffolding on x-small screens
@media (max-width: @screen-sm-max) {
  .navbar-custom-menu .navbar-nav > li {
    float: left;
  }

  //Dont't let links get full width
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left;
  }

  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
  }
}

// Collapse header
@media (max-width: @screen-header-collapse) {
  .main-header {
    position: relative;
    .logo,
    .navbar {
      width: 100%;
      float: none;
    }
    .navbar {
      margin: 0;
    }
    .navbar-custom-menu {
      float: right;
    }
  }
}

.navbar-collapse.pull-left {
  @media (max-width: @screen-sm-max) {
    float: none !important;
    + .navbar-custom-menu {
      display: block;
      position: absolute;
      top: 0;
      right: 40px;
    }
  }
}
